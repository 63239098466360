import React from 'react';
import { graphql, Link } from 'gatsby';
import P from 'prop-types';

import Layout from '../layouts/index';

const Team = ({ data }) => {
  const { author } = data.markdownRemark.frontmatter;
  const { name } = author || {};
  const { html } = data.markdownRemark;

  return (
    <Layout bodyClass="page-team">
      <div className="strip strip-white strip-diagonal">
        <div className="container pt-4 pt-md-10">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <div className="team">
                <h1 className="title">{name}</h1>
                <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
                {author.hasBlog && (
                  <Link to={`/blogg/${author.key}`}>
                    <p>{`Gå till ${name}'s blogginlägg ⯈`}</p>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        author {
          name
          key
          hasBlog
        }
      }
      html
    }
  }
`;

Team.propTypes = {
  data: P.shape({
    markdownRemark: P.shape({
      frontmatter: P.shape({
        author: P.shape({
          key: P.string,
          name: P.string,
          hasBlog: P.bool,
        }),
      }),
      html: P.string,
    }).isRequired,
  }).isRequired,
};

export default Team;
